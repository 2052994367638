import React, { Fragment } from 'react';
import {
    Datagrid,
    DateField,
    EditButton,
    List,
    NumberField,
    Responsive
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/AttachMoney';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import MobileGrid from './MobileGrid';

export const CommandIcon = Icon;

const datagridStyles = {
    total: { fontWeight: 'bold' }
};

class TabbedDatagrid extends React.Component {
    render() {
        const { classes, filterValues, ...props } = this.props;
        return (
            <Fragment>
                <Responsive
                    xsmall={<MobileGrid {...props} />}
                    medium={
                        <div>
                            <Datagrid
                                key="processed"
                                {...props}
                                // ids={this.state['processed']}
                            >
                                <DateField key="date" source="date" showTime />
                                {/* <TextField source="reference" /> */}
                                <CustomerReferenceField key="customer" />
                                <NbItemsField key="num" />
                                <NumberField
                                    key="total"
                                    source="price"
                                    options={{
                                        style: 'currency',
                                        currency: 'EUR'
                                    }}
                                    className={classes.total}
                                />
                                <EditButton key="edit" />
                            </Datagrid>
                        </div>
                    }
                />
            </Fragment>
        );
    }
}

const StyledTabbedDatagrid = withStyles(datagridStyles)(TabbedDatagrid);

const CommandList = ({ classes, ...props }) => (
    <List
        {...props}
        filterDefaultValues={{ status: 'ordered' }}
        sort={{ field: 'date', order: 'DESC' }}
        perPage={25}
    >
        <StyledTabbedDatagrid />
    </List>
);

export default CommandList;
