import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import Recover from './Recover';

export default [
    <Route
        exact
        key="configuration"
        path="/configuration"
        component={Configuration}
    />,
    <Route exact key="recover" path="/recover" component={Recover} noLayout />
];
