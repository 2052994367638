import { connect } from 'react-redux';
import { Layout } from 'react-admin';

const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            light: '#7986cb',
            main: '#118ab2',
            dark: '#118ab2',
            contrastText: '#fff'
        },
        secondary: {
            main: '#118ab2'
        }
    }
};

const lightTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#c43862'
        },
        secondary: {
            main: '#0f2c52'
        }
    },
    typography: {
        fontFamily: [
            '"Poppins"',
            '"Raleway"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(',')
    },
    background: {
        paper: '#fafafa'
    }
};

export default connect(
    state => ({
        theme: state.theme === 'dark' ? darkTheme : lightTheme
    }),
    {}
)(Layout);
