import React from 'react';
import {
    //BooleanField,
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    EditButton,
    //Filter,
    FormTab,
    List,
    LongTextInput,
    BooleanInput,
    NumberField,
    ReferenceManyField,
    Responsive,
    TabbedForm,
    TextField,
    TextInput
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Person';

import NbItemsField from '../commands/NbItemsField';
// import ProductReferenceField from '../products/ProductReferenceField';
// import StarRatingField from '../reviews/StarRatingField';
import FullNameField from './FullNameField';
//import SegmentInput from './SegmentInput';
// import SegmentsInput from './SegmentsInput';
import CustomerLinkField from './CustomerLinkField';
import MobileGrid from './MobileGrid';

export const VisitorIcon = Icon;

/*const VisitorFilter = props => (
    <Filter {...props}>
        <TextInput label="pos.search" source="q" alwaysOn />
        <DateInput source="last_seen_gte" />
        <NullableBooleanInput source="has_ordered" />
        <NullableBooleanInput source="has_newsletter" defaultValue />
        <SegmentInput />
    </Filter>
);*/

const colored = WrappedComponent => {
    const Colored = props =>
        props.record[props.source] > 500 ? (
            <span style={{ color: 'red' }}>
                <WrappedComponent {...props} />
            </span>
        ) : (
            <WrappedComponent {...props} />
        );

    Colored.displayName = `Colored(${WrappedComponent.displayName})`;

    return Colored;
};

export const ColoredNumberField = colored(NumberField);
ColoredNumberField.defaultProps = NumberField.defaultProps;

const listStyles = {
    nb_commands: { color: 'purple' }
};

export const VisitorList = withStyles(listStyles)(({ classes, ...props }) => (
    <List
        {...props}
        //filters={<VisitorFilter />}
        sort={{ field: 'last_seen', order: 'DESC' }}
        perPage={25}
    >
        <Responsive
            xsmall={<MobileGrid />}
            medium={
                <Datagrid>
                    <CustomerLinkField key="customer" />
                    <TextField source="email" />
                    {/*<DateField source="last_seen" type="date" />*/}
                    {/* <NumberField
                        key="commands"
                        source="sales.length"
                        label="resources.users.fields.commands"
                        className={classes.nb_commands}
                    /> */}
                    {/* <NumSales
                        key="commands"
                        label="resources.users.fields.commands"
                        className={classes.nb_commands}
                    /> */}

                    {/*<ColoredNumberField
                        source="total_spent"
                        options={{ style: 'currency', currency: 'EUR' }}
                    /> 
                    <DateField source="latest_purchase" showTime />*/}

                    {/* <SegmentsField key="segments" /> */}
                    <EditButton key="edit" />
                </Datagrid>
            }
        />
    </List>
));

const VisitorTitle = ({ record }) =>
    record ? <FullNameField record={record} size={32} /> : null;

const editStyles = {
    first_name: { display: 'inline-block' },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
};

export const VisitorEdit = withStyles(editStyles)(({ classes, ...props }) => (
    <Edit title={<VisitorTitle />} {...props}>
        <TabbedForm>
            <FormTab label="resources.users.tabs.identity">
                <TextInput source="name" formClassName={classes.first_name} />
                <TextField
                    type="id"
                    source="id"
                    formClassName={classes.first_name}
                />
                {/* <SegmentsInput /> */}
                <TextField
                    type="email"
                    source="email"
                    formClassName={classes.email}
                />
                <BooleanInput source="approved" label="Approved" />
            </FormTab>
            {/*<FormTab label="resources.users.tabs.address">
                <LongTextInput
                    source="address"
                    formClassName={classes.address}
                />
                <TextInput source="zipcode" formClassName={classes.zipcode} />
                <TextInput source="city" formClassName={classes.city} />
            </FormTab>*/}
            <FormTab label="resources.users.tabs.orders">
                <ReferenceManyField
                    addLabel={false}
                    reference="sales"
                    target="userId"
                >
                    <Datagrid>
                        <DateField key="date" source="date" />
                        <NbItemsField key="NbItems" />
                        <NumberField
                            key="total"
                            source="price"
                            options={{ style: 'currency', currency: 'USD' }}
                        />
                        <TextField key="status" source="status" />
                        <EditButton key="edit" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="resources.users.tabs.charges">
                <ReferenceManyField
                    addLabel={false}
                    reference="charges"
                    target="userId"
                >
                    <Datagrid>
                        <DateField key="date" source="createdAt" />
                        <TextField key="status" source="status" />
                        <NumberField
                            key="value"
                            source="value"
                            options={{ style: 'currency', currency: 'EUR' }}
                        />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            {/* <FormTab label="resources.users.tabs.subscriptions">
                <ReferenceManyField
                    addLabel={false}
                    reference="subscriptions"
                    target="customer_id"
                >
                    <Datagrid / *filter={{ status: 'approved' }} * />
                        <DateField source="date" />
                        <ProductReferenceField />
                        <StarRatingField />
                        <TextField
                            source="comment"
                            cellClassName={classes.comment}
                        />
                        <EditButton style={{ padding: 0 }} />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab> */}
            {/*<FormTab label="resources.users.tabs.stats">
                <NullableBooleanInput source="has_newsletter" />
                <DateField
                    source="first_seen"
                    style={{ width: 128, display: 'inline-block' }}
                />
                <DateField
                    source="latest_purchase"
                    style={{ width: 128, display: 'inline-block' }}
                />
                <DateField
                    source="last_seen"
                    style={{ width: 128, display: 'inline-block' }}
                />
            </FormTab>*/}
        </TabbedForm>
    </Edit>
));

export const VisitorCreate = withStyles(editStyles)(({ classes, ...props }) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="resources.users.tabs.identity">
                <TextInput
                    source="first_name"
                    formClassName={classes.first_name}
                />
                <TextInput
                    source="last_name"
                    formClassName={classes.last_name}
                />
                <TextInput
                    type="email"
                    source="email"
                    validation={{ email: true }}
                    fullWidth={true}
                    formClassName={classes.email}
                />
                <DateInput source="birthday" />
            </FormTab>
            <FormTab label="resources.users.tabs.address">
                <LongTextInput
                    source="address"
                    formClassName={classes.address}
                />
                <TextInput source="zipcode" formClassName={classes.zipcode} />
                <TextInput source="city" formClassName={classes.city} />
            </FormTab>
        </TabbedForm>
    </Create>
));
