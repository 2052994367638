import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import SettingsIcon from '@material-ui/icons/Settings';
import logo from './img/caiado.png';
//import LabelIcon from '@material-ui/icons/Label';
import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive
} from 'react-admin';
import { withRouter } from 'react-router-dom';

import { VisitorIcon } from './visitors';
import { AdminIcon } from './admins';
import { CommandIcon } from './commands';
//import { ProductIcon } from './products';
//import { CategoryIcon } from './categories';
//import { SubscriptionIcon } from './reviews';
import { ChargeIcon } from './charges';

import { withStyles } from '@material-ui/core/styles';

const items = [
    { name: 'admins', icon: <AdminIcon /> },
    { name: 'users', icon: <VisitorIcon /> },
    // { name: 'segments', icon: <LabelIcon /> },
    { name: 'sales', icon: <CommandIcon /> },
    { name: 'charges', icon: <ChargeIcon /> }
    // { name: 'products', icon: <ProductIcon /> }
    // { name: 'categories', icon: <CategoryIcon /> },
    // { name: 'subscriptions', icon: <SubscriptionIcon /> }
];

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: '100%'
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52
    }
};

const Menu = ({ sidebarOpen, classes, onMenuClick, translate, logout }) => (
    <div className={classes.main}>
        <span
            id="logo"
            className="MuiPaper-elevation1-32"
            style={{
                margin: 8,
                marginTop: 0,
                backgroundColor: '#2c2c2c',
                display: sidebarOpen ? 'flex' : 'none',
                justifyContent: 'flex-start'
            }}
        >
            <img
                alt=""
                src={logo}
                width={200}
                height={200}
                style={{
                    margin: 12
                }}
            />
        </span>
        <DashboardMenuItem onClick={onMenuClick} />
        {items.map(item => (
            <MenuItemLink
                key={item.name}
                to={`/${item.name}`}
                primaryText={translate(`resources.${item.name}.name`, {
                    smart_count: 2
                })}
                leftIcon={item.icon}
                onClick={onMenuClick}
            />
        ))}
        <MenuItemLink
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
        />
        <Responsive xsmall={logout} medium={null} />
    </div>
);

const enhance = compose(
    withRouter,
    withStyles(styles),
    connect(
        state => {
            console.log(state);
            return {
                theme: state.theme,
                locale: state.i18n.locale,
                sidebarOpen: state.admin.ui.sidebarOpen
            };
        },
        {}
    ),
    translate
);

export default enhance(Menu);
