// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:86ebacf4-a390-47c1-acef-6b45e592251e',
    'aws_cognito_region': 'us-east-1',
    'aws_mobile_analytics_app_id': '743d079a4eeb45618daf44bae2934ea9',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': 'fe495fcd-c173-4488-b769-a1e2acb1eb17',
    'aws_project_name': 'Caiado Backoffice',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'caiadobackoffice-mobilehub-1752964635',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_PKZ1EeBYw',
    'aws_user_pools_web_client_id': '7mh9oh3mc6pji8e03bl3v60p39'
};

export default awsmobile;
